import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header/header'
import './layout.css'

const Layout = ({ unsetSpacing, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          paddingTop: 0,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: 'center',
          width: '100%',
          flexGrow: `1`,
          minHeight: `90vh`,
        }}>
          <div
            style={{
              minWidth: 320,
              maxWidth: unsetSpacing ? '100%' : 1400,
              width: '100%',
              padding: unsetSpacing ? 0 : '1rem',
              marginBottom: `2rem`,
            }}
          >
            {children}
          </div>
          <div style={{marginTop:`auto`, padding:`2rem`, width:`100%`, backgroundColor:`#9CDECF`}}>
            <footer
              style={{
                width: `50%`,
                maxWidth: 1400,
                marginLeft: `auto`,
                marginRight: `auto`
              }}
            >
                <p>© {new Date().getFullYear()} <a href="https://embarccollective.com">Embarc Collective</a> ||
                Made with <span role="img" aria-label="love">❤️</span> in Tampa Bay ||
                Built with <a href="https://www.gatsbyjs.org">Gatsby</a></p>
            </footer>
          </div>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

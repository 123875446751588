import React from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom';
import Embarc_Logo from '../../images/Embarc_Logo.svg';
import Button from '../button/button';


const Header = ({ siteTitle }) => (
  <Headroom
    style={{
      background: '#9CDECF',
      height: 160,
    }}
  >
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 1.5rem 0 1.5rem'
      }}
    >
      <a href="https://embarccollective.com" style={{display:'flex', alignContent: 'center'}}>
        <img style={{margin:`auto 0 auto 0`, maxWidth:160}} src={Embarc_Logo} width='100%' height="auto" alt="Embarc Collective logo" />
      </a>
      <div style={{
          padding: `2rem`,
          display: 'flex',
          alignItems: 'flex-end',
        }}>
          <Button text="All Reports" url="/" use_border={false} theme="dark" link={true} new_tab={false} line="line" header/>
        </div>
    </div>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

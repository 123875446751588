import React from 'react'
import PropTypes from 'prop-types'
import './button.css'
import '../utility.css'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Button = ({text, url, use_border, theme, link, new_tab, line, header}) => {
  if(link === true) {
    return (     
      <Link to={url} style={!use_border ? {border:0} : {}} className={`button ${theme ? theme : "dark"}-hover-${line ? line : "fill"}-animate`}>
        {header ? <h3 className='no-margin'>{text}</h3> : text}
      </Link>
    )
  }
  else if(new_tab) {
    return (     
      <OutboundLink href={url} style={!use_border ? {border:0} : {}} className={`button ${theme ? theme : "dark"}-hover-${line ? line : "fill"}-animate`} target="_blank" rel="noopener noreferrer">
        {text}
      </OutboundLink>
    )
  }
  else {
    return (     
      <OutboundLink href={url} style={!use_border ? {border:0} : {}} className={`button ${theme ? theme : "dark"}-hover-${line ? line : "fill"}-animate`}>
        {text}
      </OutboundLink>
    )
  }
}

//text, url, use_border, theme, link, new_tab, line, header
Button.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  link: PropTypes.string,
  theme: PropTypes.string,
  new_tab: PropTypes.bool,
  line: PropTypes.string,
  header: PropTypes.bool,
  use_border: PropTypes.bool,
}

export default Button;